<!--
 * @Description: 卡属性
 * @Author: ChenXueLin
 * @Date: 2021-10-19 11:17:06
 * @LastEditTime: 2022-07-15 15:56:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="simProp">
              <el-input
                v-model="searchForm.simProp"
                placeholder="卡属性"
                title="卡属性"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-add_line"
            title="创建卡属性"
            @click="addProp('add')"
          ></i>
          <i
            class="e6-icon-clear_line"
            title="删除卡属性"
            @click="deleteProp"
          ></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="handleEnable(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="停用"
            @click="handleEnable(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 创建/编辑卡属性弹框  start -->
    <el-dialog
      v-dialogDrag
      :title="dialogTitle"
      :visible.sync="addVisible"
      width="620px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="addLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="addForm"
        label-width="130px"
        :model="addForm"
        :rules="addRules"
      >
        <el-form-item label="属性名称" prop="simProp">
          <el-input
            v-model.trim="addForm.simPropName"
            placeholder="属性名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 创建/编辑卡属性弹框  end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  simPropList,
  deleteSimProp,
  enableSimProp,
  disenableSimProp,
  createSimProp,
  updateSimProp
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "SimAttribute",
  components: {},
  data() {
    return {
      total: 0,
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      searchForm: {
        createdBy: "", //创建人
        simProp: "", //sim卡属性
        createTime: [], //创建时间
        createStartTime: "", //创建开始时间
        createEndTime: "", //创建结束时间
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "createdTime", // 默认创建时间排序
        sortDir: "descending" // 默认降序
      },
      columnData: [
        {
          fieldName: "simPropName",
          display: true,
          fieldLabel: "卡属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isEnable",
          display: true,
          fieldLabel: "状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeString",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: [], //选中的数据
      /*****创建卡属性  start */
      addVisible: false,
      dialogTitle: "创建卡属性",
      addForm: {
        simPropName: "" //属性名称
      },
      addLoading: false,
      addRules: {
        simPropName: [
          {
            required: true,
            message: "请输入属性名称",
            trigger: ["blur", "change"]
          }
        ]
      },
      queryListAPI: simPropList
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createStartTime = val[0] || "";
        this.searchForm.createEndTime = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.simPropId;
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.addProp("edit", row);
      }
    },
    //删除卡属性
    deleteProp() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      } else {
        this.$confirm("是否确认删除所选卡属性?", "删除卡属性", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.deletePropReq();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      }
    },
    //删除卡属性请求
    async deletePropReq() {
      try {
        this.loading = true;
        let res = await deleteSimProp({
          list: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击启用、停用
    handleEnable(type) {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      } else {
        let confirmTitleKeyWord = type == 1 ? "启用" : "停用";
        this.$confirm(
          `是否确认${confirmTitleKeyWord}所选卡属性?`,
          `${confirmTitleKeyWord}卡属性`,
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            if (type == 1) {
              this.enableProp();
            } else {
              this.disenableProp();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: `已取消${confirmTitleKeyWord}`
            });
          });
      }
    },
    //启用卡属性请求
    async enableProp() {
      try {
        this.loading = true;
        let res = await enableSimProp({
          list: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //停用卡属性请求
    async disenableProp() {
      try {
        this.loading = true;
        let res = await disenableSimProp({
          list: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("停用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /*******创建/编辑卡属性*************/
    //创建卡属性
    addProp(type, row) {
      this.addVisible = true;
      this.type = type;
      if (type == "add") {
        this.dialogTitle = "新增卡属性";
      } else {
        this.dialogTitle = "编辑卡属性";
        this.addForm = row ? { ...row } : this.addForm;
      }
    },
    //点击确定
    handleSubmit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.type == "add") {
            this.createSimProp();
          } else {
            this.updateSimProp();
          }
        }
      });
    },
    //新增属性请求
    async createSimProp() {
      try {
        this.addLoading = true;
        let res = await createSimProp(this.addForm);
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addLoading = false;
      }
    },
    //编辑属性请求
    async updateSimProp() {
      try {
        this.addLoading = true;
        let res = await updateSimProp(this.addForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addLoading = false;
      }
    },
    handleClose() {
      this.addForm = {
        simPropName: ""
      };
      this.$refs.addForm.resetFields();
      this.addVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
